import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Front Squats 4×8\\@70%1RM`}</p>
    <p>{`Ball Leg Curls 4×8`}</p>
    <p>{`then,`}</p>
    <p>{`21-15-9 reps each:`}</p>
    <p>{`Thrusters (95/65)(RX+115/75)`}</p>
    <p>{`T2B`}</p>
    <p>{`for time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Friday will be our 12 Days of Christmas wod at 9:00 & 10:30.
All other classes are cancelled.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`We will be closed Saturday, Christmas Day.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      